/* ==========================================================================
   USER CUSTOM
   ========================================================================== */

// $doc-font-size: 13px;
$right-sidebar-width-narrow: 225px !default;
$right-sidebar-width: 350px !default;
$right-sidebar-width-wide: 450px !default;
$reddit-color: #ff4500 !default;
$background-color: #eeeeee !default;
$text-color: #222831 !default;
$muted-text-color: #393e46 !default;
$primary-color: #0092ca !default;
$border-color: mix(#fff, #393e46, 75%) !default;
$footer-background-color: $primary-color !default;
$link-color: #393e46 !default;
$masthead-link-color: $text-color !default;
$masthead-link-color-hover: $text-color !default;
$navicon-link-color-hover: mix(#fff, $text-color, 80%) !default;
// .page-title {
//   font-family: $global-font-family;
//   font-size: $type-size-8;
// }
.page__footer {
  color: #fff !important; // override
}

.page__footer-follow .social-icons .svg-inline--fa {
  color: inherit;
}
#main {
  margin-top: 0 !important;
  padding-top: 2em !important;
}
#main, .masthead__inner-wrap {
  max-width: 100% !important;
}

pre.highlight {
  font-size: 13px;
  line-height: 18px;
}

.sidebar.sticky {
  text-align: center;
  width: calc(350px - 1em) !important;
  color: #eee !important;
  opacity: 1 !important;
  background-color: #153045 !important;
  // position: fixed;
  margin-top: -2em !important;
  padding-top: 4em !important;
  min-height: 100vh !important;
  height: 100vh !important;
  margin-left: -1em !important;
  padding-left: 1em !important;
  padding-right: 1em;
  margin-bottom: -1em !important;
  top: 0 !important;
  bottom: 0 !important;
  .author__name {
    font-size: 1.2em !important;
  }
  .author__bio {
    padding-top:    1em !important;
    padding-bottom: 1em !important;
    font-size: 0.95em !important;
  }
  .author__urls-wrapper {
    li a {
      padding-top:    0.3em !important;
      padding-bottom: 0.3em !important;
      font-size: 1.4em !important;
    }
  }
}

.page, .archive, .page__related {
  // width: calc(100% - 350px) !important;
  padding-right: 75px !important;
}
.archive {
  font-size: 1.3em;
}
